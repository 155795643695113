<template>
  <section>
    <!-- <land-banner
      :items="banItems"
      :height="classify.height"
      :points="classify.points"
      :cycle="classify.cycle"
      :interval="classify.interval"
      :hide-delimiters="wdHideDelimiters"
      :show-arrows="wdShowArrows"
    /> -->
    <land-hero-alts
      v-if="actBanners.length"
      :titled="false"
      :items="actBanners"
      :points="actPoints"
    />
    <land-section
      id="activity_header"
      space="10"
    >
      <v-container
        style="max-width: 1200px;"
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <div
              class="co-flex-row co-justify-start co-items-center co-h-full"
            >
              <v-icon color="blue-grey">
                mdi-clock-time-five-outline
              </v-icon>
              <span class="ml-2 blue-grey--text">
                {{ actInfo.hint }}
              </span>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <div class="co-flex-col co-justify-center co-items-center co-w-full">
              <v-btn
                :disabled="btnPutin.disabled"
                color="primary"
                height="48"
                width="200"
                @click="onPutin"
              >
                {{ btnPutin.text }}
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <div class="pa-5" />
          </v-col>
        </v-row>
      </v-container>
    </land-section>
    <land-section
      id="activity_tabs"
      space="4"
    >
      <v-container
        style="max-width: 1200px;"
      >
        <v-tabs
          v-model="currTab"
          background-color="transparent"
          class="d-flex flex-row justify-center align-center"
        >
          <v-tab
            v-for="(tab, tabIndex) in tabList"
            :key="tabIndex"
            :value="tab.name"
            :href="`#${tab.name}`"
            class="text-h6"
          >
            {{ tab.title }}
          </v-tab>
        </v-tabs>
      </v-container>
    </land-section>
    <!-- <v-divider /> -->
    <land-section
      id="activity_items"
      space="20"
    >
      <v-container
        style="max-width: 1200px;"
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
          >
            <v-tabs-items v-model="currTab">
              <v-tab-item
                value="brief"
                class="white"
              >
                <template v-if="actInfo.model === 'image'">
                  <v-img
                    v-for="(img, index) in actInfo.content"
                    :key="`img_${index}`"
                    :src="img.value"
                    cover
                  />
                </template>
                <template v-else>
                  <div
                    class="ql-editor"
                    v-html="actInfo.content"
                  />
                </template>
              </v-tab-item>
              <v-tab-item
                value="works"
                class="grey lighten-4"
              >
                <all-works
                  :activity-id="actInfo.activityId"
                  :tags="actInfo.tags"
                />
              </v-tab-item>
              <v-tab-item
                value="chosen"
                class="grey lighten-4"
              >
                <chosen-works
                  :activity-id="actInfo.activityId"
                  :tags="actInfo.tags"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="6"
            sm="12"
          >
            <div class="d-flex flex-row align-center ml-3">
              <span class="blue-grey--text">上一个：</span>
              <span
                v-if="prev.activityId"
                class="blue--text"
                :class="[prev.activityId ? 'cursor-handle' : '']"
                @click="toPrev"
              >
                {{ prev.title }}
              </span>
              <span
                v-else
                class="blue-grey--text text--lighten-3"
              >(无)</span>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="12"
          >
            <div
              class="d-flex flex-row justify-end align-center mr-3"
            >
              <span class="blue-grey--text">下一个：</span>
              <span
                v-if="next.activityId"
                class="blue--text"
                :class="[next.activityId ? 'cursor-handle' : '']"
                @click="toNext"
              >
                {{ next.title }}
              </span>
              <span
                v-else
                class="blue-grey--text text--lighten-3"
              >(无)</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
    <dialog-upload-types
      ref="dlgUploadTypes"
      @confirm="confirmUploadType"
    />
    <dialog-alert ref="dlgAlert" />
  </section>
</template>

<script>
  import mixActivityDetail from '@/pages/mixins/activity/mix.activity.detail'

  export default {
    components: {
      ChosenWorks: () => import('@/pages/sections/activity/ChosenWorks.vue'),
      AllWorks: () => import('@/pages/sections/activity/AllWorks.vue'),
      DialogUploadTypes: () => import('@/pages/sections/comm/DialogUploadTypes.vue'),
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    mixins: [
      mixActivityDetail
    ],
    data () {
      return {}
    },
    created () {
      const activityId = this.$route.query.activityId || ''
      const title = this.$route.query.title
      const name = this.$route.query.name
      // this.actMeta = JSON.parse(lib.cookie.get('ActivityDetail'))
      this.actMeta = {
        activityId,
        title,
        name
      }

      if (activityId) {
        this.getActivity(activityId)
      }
    },
  }
</script>

<style scoped>
  .cursor-handle {
    cursor: pointer;
  }
</style>
