
import api from '@/api/co.api'
import user from '@/api/co.user'

import web from '@/api/web/co.web'

import mixPreset from '@/pages/mixins/activity/mix.activity.preset'

const parts = mixPreset.DetailParts
const names = mixPreset.DetailNames

export default {
  data () {
    return {
      actMeta: {},
      actInfo: {
        activityId: '',
        title: ''
      },
      prev: {},
      next: {},
      current: {},
      btnPutin: {
        text: '上传参赛作品',
        disabled: false,
        visible: false,
        loading: false
      },
      currTab: names.BRIEF,
      tabList: [
        parts[names.BRIEF],
        parts[names.WORKS],
        parts[names.CHOSEN],
      ],
      actBanners: [],
      actPoints: {
        xs: 200,
        sm: 320,
        md: 410,
        lg: 720,
        xl: 800
      },
    }
  },
  methods: {
    toPrev () {
      const actId = this.prev.activityId || ''
      if (actId) {
        this.getActivity(actId)
      }
    },
    toNext () {
      const actId = this.next.activityId || ''
      if (actId) {
        this.getActivity(actId)
      }
    },
    showAlert (type, message, delay = 4000) {
      const dlg = this.$refs.dlgAlert
      if (dlg) {
        dlg.show({
          type,
          message,
          delay
        })
      }
    },
    toLogin () {
      const params = {
        mode: 'phone'
      }
      api.page.navigate(this, 'login', params)
    },
    toPutin (current = '') {
      const params = {
        activityId: this.actInfo.activityId,
        title: this.actInfo.title,
        current,
        submitType: this.actInfo.submitType,
        imageTags: this.actInfo.imageTags,
        videoTags: this.actInfo.videoTags,
        articleTags: this.actInfo.articleTags
      }
      api.page.navigate(this, 'activityPutin', params)
    },
    confirmUploadType (ev) {
      // console.log({ ev })
      this.toPutin(ev.type)
    },
    toSelectType () {
      const submitType = api.comm.trim(this.actInfo.submitType)
      const arr = submitType.split(',')
      if (arr.length > 1) {
        const dlg = this.$refs.dlgUploadTypes
        if (dlg) {
          const options = {
            submitType: this.actInfo.submitType
          }
          dlg.show(options)
        }
      } else {
        this.toPutin()
      }
    },
    checkIdentiy (info = {}) {
      const valids = ['医务工作者', '医学生']
      const identity = info.identity || ''
      if (valids.indexOf(identity) === -1) {
        const msg = `活动只能是${valids.join('/')}身份的用户参与`
        this.showAlert('warning', msg, 7000)
      } else {
        this.toSelectType()
      }
    },
    checkUser () {
      const me = this
      const executed = function (res) {
        // console.log({ res })
        if (res.status) {
          const data = res.data || {}
          if (!data.realname || !data.organizationName) {
            // api.page.navigate(me, 'certificate')
            me.$confirm('请完善个人信息后再参与活动', '提示', {
              confirmButtonText: '去完善',
              cancelButtonText: '暂不',
              type: 'info'
            }).then(() => {
              api.page.navigate(me, 'certificate')
            }).catch(() => {
              me.$message({
                type: 'info',
                message: '已取消完善个人信息！'
              })
            })
          } else {
            me.checkIdentiy(data)
          }
        } else {
          const data = res.data || {}
          const msg = `${data.code || '9000'} - ${data.desc || '未知错误'}`
          me.showAlert('error', msg)
        }
      }

      user.info.gain({
        executed
      })
    },
    onPutin (ev) {
      const me = this
      // me.toSelectType()
      const callback = function (res) {
        if (res.status) {
          // me.toSelectType()
          me.checkUser()
        } else {
          me.$confirm('登录后才能上传作品, 是否现在登录?', '登录提示', {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'info'
          }).then(() => {
            me.toLogin()
          }).catch(() => {
            me.$message({
              type: 'info',
              message: '已取消登录！'
            })
          })
        }
      }

      user.login.isLogined(callback)
    },
    getActivity (actId) {
      const me = this
      this.actBanners = []
      const executing = function () {
      }

      const executed = function (ev) {
        // console.log('mix.activity executed, ev: %o', ev)
        if (ev.status) {
          const data = ev.data || {}
          me.prev = data.previous || {}
          me.next = data.next || {}
          me.current = data.current || {}
          me.actInfo = web.activity.convertItem(me.current)
          me.actInfo.tags = `${me.actInfo.imageTags},${me.actInfo.videoTags},${me.actInfo.articleTags}`
          me.actBanners.push({
            cover: me.actInfo.cover
          })

          me.btnPutin.disabled = !me.actInfo.valid
        }
      }

      web.activity.getCurrent({
        id: actId,
        executing,
        executed
      })
    },
  }
}
